import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { motion, AnimatePresence } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import {
  FullPageLoader,
  getUser,
  Login,
  loginValidationSchema,
  tokenStorage,
  useLogin,
  useRedirection,
  userIDStorage,
} from '@/features/auth'
import { axiosInstance, setAxiosDefaultToken } from '@/lib/axios'
import { useAuth } from '@/contexts/Authentication'
import { AnimatedText, Button, InputField } from '@/components'
import { useToggle } from '@/hooks'
import Head from 'next/head'

const Login = () => {
  const placeholderText = [
    [
      {
        type: 'paragraph',
        text: '“Choose a job you love, and you will never have to work a day in your life.”',
      },
      { type: 'heading2', text: '~ Confucius' },
      {
        type: 'paragraph',
        text: 'Chinese Philosopher',
      },
    ],
    [
      {
        type: 'paragraph',
        text: '“Opportunities don\'t happen. You create them.”',
      },
      { type: 'heading2', text: '~ Chris Grosser' },
      {
        type: 'paragraph',
        text: 'Entrepreneur',
      },
    ],
    [
      {
        type: 'paragraph',
        text: '“I find that the harder I work, the more luck I seem to have.”',
      },
      { type: 'heading2', text: '~ Thomas Jefferson' },
      {
        type: 'paragraph',
        text: 'American Founding Father',
      },
    ],
    [
      {
        type: 'paragraph',
        text: '“Delegate your work. Stop spending dollar time on penny jobs.”',
      },
      { type: 'heading2', text: '~ Mary Kay Ash' },
      {
        type: 'paragraph',
        text: 'American Businesswoman and Entrepreneur',
      },
    ],
    [
      {
        type: 'paragraph',
        text: '“The future depends on what you do today.”',
      },
      { type: 'heading2', text: '~ Mahatma Gandhi' },
      {
        type: 'paragraph',
        text: 'Indian Political Ethicist',
      },
    ],
    [
      {
        type: 'paragraph',
        text: '“The only way to achieve the impossible is to believe it is possible.”',
      },
      { type: 'heading2', text: '~ Charles Kingsleigh' },
      {
        type: 'paragraph',
        text: 'Fictional Character from Alice in Wonderland',
      },
    ],
  ]
  

  const [currentQuoteIndex, setCurrentQuoteIndex] = React.useState(0)
  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQuoteIndex((currentQuoteIndex + 1) % placeholderText.length)
    }, 7500)

    return () => clearInterval(interval)
  }, [currentQuoteIndex])

  const { authDispatch } = useAuth()
  const [isLoadingPage, setIsLoadingPage] = React.useState(true)
  const { redirect } = useRedirection()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Login>({
    resolver: yupResolver(loginValidationSchema),
  })
  const { mutate, isLoading } = useLogin()
  const { show, handleToggle } = useToggle()
  const token = tokenStorage.getToken()

  React.useEffect(() => {
    const userID = userIDStorage.getUserID()
    ;(async () => {
      if (!userID) {
        setIsLoadingPage(false)
        return
      }

      const user = await getUser(userID)

      if (user && !isLoading && token) {
        setAxiosDefaultToken(token, axiosInstance)

        if (authDispatch) {
          authDispatch({
            type: 'LOGIN',
            payload: {
              user,
            },
          })

          authDispatch({ type: 'STOP_LOADING' })
          redirect(user)
        }
      }
      setIsLoadingPage(false)
    })()
  }, [isLoadingPage, token])

  if (isLoadingPage) {
    return <FullPageLoader />
  }

  const onSubmit = (payload: Login) => {
    mutate(payload)
  }

  const container = {
    visible: {
      transition: {
        staggerChildren: 0.025,
      },
    },
  }

  return (
    <>
      <Head>
        <title>Scout4Job | Login</title>
        <meta
          name="description"
          content="Login into scout4job to start posting a job or applying for a job"
        />
        <meta
          name="keywords"
          content="job openings, employment opportunities, career listings, job search, find jobs, job vacancies, hiring now, work opportunities, job applications, job board, job listings, job portal, jobs near me, job search engine, online job search, job posting site, job classifieds, job alerts, job seekers, job match, free job posting sites in nigeria, recruitment portals in nigeria, remote jobs, full-time jobs, interviews, job seeker tips, interview tips, job fair, resume builder, internship, entry-level jobs"
        />
        <meta property="og:title" content="Scout4job | Login" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Login into scout4job to start posting a job or applying for a job."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/scout4job/image/upload/v1696514292/uc_g0zqgl.png"
        />
        <meta property="og:url" content="https://scout4job.com/login" />
        <meta name="twitter:title" content="Scout4Job | Login" />
        <meta
          name="twitter:description"
          content="Login into scout4job to start posting a job or applying for a job"
        />
        <meta name="twitter:site" content="@scout4job" />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/scout4job/image/upload/v1696514292/uc_g0zqgl.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
      <motion.div
        className="flex"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ type: 'linear' }}
      >
        <div className="loginBack relative top-0 hidden h-screen flex-1 items-center justify-center text-center lg:flex">
          <div className="relative mt-[300px] flex h-[500px] w-full flex-col items-center">
            <Link href="/" className="mt-[-30px] flex justify-center">
              <Image
                src="/assets/logo.png"
                alt="scout4Job-logo"
                className="md:cursor-pointer"
                height={28.21}
                width={185.08}
              />
            </Link>
            <div className="relative h-[400px] w-[491px]">
              <AnimatePresence>
                {placeholderText[currentQuoteIndex] && (
                  <motion.div
                    key={currentQuoteIndex}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={container}
                  >
                    <div className="absolute mx-auto mt-[68px] w-[491px]">
                      <AnimatedText
                        quote={placeholderText[currentQuoteIndex]}
                      />
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>

        <div className="flex h-screen flex-1 items-center overflow-y-auto">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mx-auto w-[360px] px-8 pt-10 md:px-0"
          >
            <div className="mb-10 flex justify-start lg:hidden">
              <Link href="/">
                <Image
                  src="/assets/logo-black.png"
                  alt="logo"
                  className="mx-auto md:cursor-pointer"
                  height={21.65}
                  width={188.53}
                />
              </Link>
            </div>
            <h2 className="text-[40px]">Login</h2>
            <p className="text-base ">Find the job made for you!</p>

            <div className="mt-8 space-y-4">
              <InputField
                label="Email Address"
                registration={{ ...register('email') }}
                hasError={errors.email}
                errorMessage={errors.email?.message}
                isRequired
              />
              <InputField
                type={show ? 'text' : 'password'}
                label="Password"
                registration={{ ...register('password') }}
                hasError={errors.password}
                errorMessage={errors.password?.message}
                handleShowPassword={handleToggle}
                withIcon
                isRequired
              />
            </div>
            <div className="flex justify-end">
              <Link
                href="/forgot-password"
                className="my-2 text-sm text-primary"
              >
                Forgot Password?
              </Link>
            </div>

            <Button
              disabled={isLoading}
              isLoading={isLoading}
              className="w-full"
              type="submit"
            >
              Login
            </Button>
            <p className="mt-10 text-center">
              Not registered?{' '}
              <Link href="/sign-up" className="text-primary ">
                Create Account
              </Link>
            </p>
          </form>
        </div>
      </motion.div>
    </>
  )
}

export default Login
